<template>
  <!-- details modal  -->
  <b-modal
    size="lg"
    scrollable
    :id="id"
    :title="title"
    @ok="handleOk"
    @show="resetModal"
    @shown="onShown"
  >
    <div class="d-block text-center"></div>

    <form class="form" novalidate="novalidate" id="add_transfer_form">
      <b-form-group
        id="fieldset-1"
        label="Cesjonariusz - nr karty"
        label-for="cardNo"
      >
        <b-form-input
          v-model="cardNo"
          name="cardNo"
          ref="cardNo"
          placeholder="nr karty"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="fieldset-2"
        label="Ilość punktów"
        label-for="points"
      >
        <b-form-input
          v-model="points"
          name="points"
          ref="points"
          type="number"
          placeholder="ilość punktow"
        ></b-form-input>
      </b-form-group>      
    </form>
  </b-modal>
  <!-- end of details modal -->
</template>

<script>
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

export default {
  name: "AddTransferModal",
  props: {
    id: String,
    title: String
  },
  data() {
    return {
      cardNo: "",
      points: null,
    };
  },
  mounted() {},
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.transferForm.validate().then(v => {
        if (v === "Valid") {
          ApiService.post("/transfer/add", {
            cardNo: this.cardNo,
            points: this.points
          }).then(() => {
            this.refreshTransfers();
            this.$nextTick(() => {
              this.$bvModal.hide(this.$props.id);
            });
          });
        }
      });
    },
    resetModal() {
      this.cardNo = "";
      this.points = null;
    },

    refreshTransfers() {
      ApiService.get("/transfers").then(response => {
        const transfers = response.data;
        this.$emit("refreshData", transfers);
      });
    },
    onShown() {
      const transfer_form = KTUtil.getById("add_transfer_form");

      this.transferForm = formValidation(transfer_form, {
        fields: {
          cardNo: {
            validators: {
              notEmpty: {
                message: "Proszę wpisać numer karty"
              },
              blank: {}
            }
          },
          points: {
            validators: {
              notEmpty: {
                message: "Proszę wpisać liczbę punktów"
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    }
  }
};
</script>
