<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        <b>Cesje</b> w tym miejscu możesz złożyć wniosek o cesję swoich punktów
        na poczet innego uczestnika Programu Lojalnościowego.
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview 
          v-bind:title="'Lista zgłoszonych cesji'"
          v-if="state === 'loaded'"
        >
          <template v-slot:toolbar-action>
            <div>
              <b-button
                variant="primary"
                size="sm"
                class="mb-1"
                @click="showModal()"
              >
                dodaj <b-icon icon="plus" aria-hidden="true"></b-icon>
              </b-button>
            </div>
          </template>        
          <template v-slot:preview>
            <div>
              <b-row>
                <b-col></b-col>
                <b-col lg="6" class="my-1">
                  <b-form-group
                    label="Filtuj"
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                      <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="szukaj..."
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >wyczyść</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-table
                ref="transferstable"
                responsive
                striped
                hover
                :items="transfers.items"
                :fields="transfers.fields"
                :tbody-tr-class="rowClass"   
                @row-clicked="rowClickHandler"             
                :filter="filter"
              >
                <template #cell(actions)="row">
                  <b-button
                    v-if="row.item.status == 'ZŁOŻONA'"
                    variant="danger"
                    size="sm"
                    class="mb-1"
                    @click="deleteTransfer(row.item)"
                  >
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </div>
          </template>
        </KTCodePreview>
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>        
      </div>
    </div>
    <template>
      <b-modal
        size="lg"
        scrollable
        ref="transferDetail"
        :title="'Status: ' + transfer.status"
      >
        <b-container>
          <div class="row">
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-4">
                      Cesja na:
                    </div>
                    <div class="col-md-8">
                      <b-badge variant="success">{{ transfer.cardNo }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-4">
                      Akronim:
                    </div>
                    <div class="col-md-8">
                      {{ transfer.erpUser.acronym }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-4">
                      E-mail:
                    </div>
                    <div class="col-md-8">
                      {{ transfer.erpUser.email }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div class="col-md-6">
              <b-list-group>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Cedowane punkty:
                    </div>
                    <div class="col-md-6">
                      <b-badge variant="warning">{{ transfer.points }}</b-badge>
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Imię nazwisko:
                    </div>
                    <div class="col-md-6">
                      {{ transfer.erpUser.person }}
                    </div>
                  </div>
                </b-list-group-item>
                <b-list-group-item>
                  <div class="row">
                    <div class="col-md-6">
                      Telefon:
                    </div>
                    <div class="col-md-6">
                      {{ transfer.erpUser.phone }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>
          <hr />
          <b-form-group
            id="fieldset-1"
            label="Odpowiedź Administratora"
            label-for="response"
            v-if="
              undefined !== transfer.response &&
                transfer.response != null &&
                transfer.response.length > 0
            "
          >
            <b-row>
              <b-col cols="12">
                <b-form-textarea
                    :value="transfer.response"
                    :disabled="true"
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-form-group>
          <span v-else> <i>... oczekuje na odpowiedź Administratora </i></span>
        </b-container>
      </b-modal>
    </template>    
    <AddTransferModal
      :id="id"
      :title="'Nowa Cesja'"
      @refreshData="onSave"
    ></AddTransferModal>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import AddTransferModal from "@/view/pages/customer/transfer/AddTransferModal.vue";
import Swal from "sweetalert2";

export default {
  name: "TransferPoints",
  data() {
    return {
      state: "loading",
      transfers: {
        fields: [
          {
            key: "date",
            label: "Data",
            sortable: true
          },    
          {
            key: "points",
            label: "Punkty",
            sortable: true
          },
          {
            key: "cardNo",
            label: "Na kartę",
            sortable: true
          },          
          {
            key: "status",
            label: "Status",
            sortable: true
          },
          {
            key: "actions",
            label: "Akcje",
            thClass: "action-column"
          }
        ],
        items: []
      },
      transfer: {
        erpUser: {}
      },
      id: "addTransfer",
      filter: null      
    };
  },
  components: {
    KTCodePreview,
    AddTransferModal
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "ODRZUCONA") return "table-danger";
      if (item.status === "ZAAKCEPTOWANA") return "table-success";
    },
    rowClickHandler(record) {
      this.$refs.transferDetail.show();
      this.transfer = record;
    },    
    getTransfers() {
      ApiService.get("/transfers").then(response => {
        this.transfers.items = response.data;
        this.state = "loaded";
      });
    },
    showModal() {
      this.$bvModal.show("addTransfer");
    },
    onSave(transfers) {
      this.transfers.items = transfers;
    },
    deleteTransfer(row) {
      Swal.fire({
        title: "Czy napewno chcesz usunąć wpis?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#009136",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak",
        cancelButtonText: "Nie"
      }).then(result => {
        if (result.value) {
          ApiService.delete("/transfer/" + row.id).then(() => {
            this.getTransfers();
          });
        }
      });
    },  
    status(transfer) {
      switch (transfer.status) {
        case "ZŁOŻONA":
          return "Złożona";
        case "ODRZUCONA":
          return "Odrzucona";
        case "ZAAKCEPTOWANA":
          return "Zaakceptowana";
        default:
          return "Nieznany";
      }
    }   
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Moje cesje" },
      { title: "" }
    ]);
    this.getTransfers();
  }
};
</script>
